<template>
  <div class="app-container">
    <div style="margin-top:10px">
      <el-button type="primary"  size="small" @click="getList" plain>刷新</el-button>
      <el-input v-model="listQuery.mobile" size="small" placeholder=" 请输入手机号" style="width:200px;margin-left: 10px;margin-right: 10px;"></el-input>
      <el-button size="small" type="primary" @click="getList">搜索</el-button>
      <!-- <el-button @click="delall()" type="danger" size='small' plain>批量删除</el-button> -->
    </div>
    <el-table :height="contentStyleObj" v-loading="listLoading" :key="tableKey" :data="list" border fit highlight-current-row style="width: 100%;margin-top:15px;" @sort-change="sortChange">
      <!-- <el-table-column type="selection" align="center" width="35" /> -->
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="上次心跳时间" width="200" >
        <template #default="scope">
          <span>{{ $parseTime(scope.row.Heartbeat,'{y}-{m}-{d} {h}:{i}:{s}')}}</span>
        </template>
      </el-table-column>

      <el-table-column label="接收手机号" width="200" >
        <template #default="scope">
          <span>{{ scope.row.Mobile }}</span>
        </template>
      </el-table-column>

      <el-table-column label="状态" width="200" >
        <template #default="scope">
          <span v-if="scope.row.status == 'ok'" style="color:green">在线</span>
          <span v-else style="color:red">不在线</span>

        </template>
      </el-table-column>

    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import {  getSmsAppList } from '@/api/login'
export default {
  name: 'smsHeartbeat',

  data() {
    return {
      listQuery:{
        page:1,
        limit:20
      },
      total:0,
      list:[],
      contentStyleObj:{}, //高度变化
      listLoading:false
    };
  },
  created(){
    this.contentStyleObj=this.$getHeight(246)
    this.getList()
  },
  mounted() {
    
  },

  methods: {
    getList(){
      this.listLoading = true
      getSmsAppList(this.listQuery).then(res => {
        this.listLoading = false;
        if(res.data.data.list){
          this.list = res.data.data.list
          this.total = res.data.data.total;
        }else{
          this.list = []
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>

</style>